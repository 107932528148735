<template>
  <!--  <h1>Dashboard</h1>-->
  <section
    id="dashboard-ecommerce"
  >
    <b-row class="match-height">
      <b-col
        v-if="$can('store', 'Users')"
        class="d-block"
        xl="4"
        md="4"
      >
        <create-user />
      </b-col>
      <b-col
        v-else
        class="d-block"
        xl="4"
        md="4"
      >
        <upgrade-from-cliente />
      </b-col>
      <b-col
        class="d-block"
        lg="8"
      >
        <downline-counter
          v-if="Object.keys(getStatistics()).length > 0"
          :statistics="getStatistics()"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        v-if="$can('store', 'Orders') && $can('store','Users')"
        class="d-block"
        lg="6"
      >
        <information-table
          v-if="Object.keys(getStatistics()).length > 0"
          :statistics="getStatistics()"
        />
      </b-col>
      <b-col
        v-if="$can('store', 'Orders') && $can('store','Users')"
        class="d-block"
        lg="6"
      >
        <user-near-qualification
          v-if="Object.keys(getStatistics()).length > 0"
          :statistics="getStatistics()"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        v-if="$can('store', 'Orders') && $can('store','Users')"
        class="d-block"
        lg="12"
      >
        <vpcs-card
          v-if="Object.keys(getStatistics()).length > 0"
          :statistics="getStatistics()"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        v-if="$can('store', 'Orders') && $can('store','Users')"
        class="d-block"
        lg="3"
      >
        <user-commissions
          v-if="Object.keys(getStatistics()).length > 0"
          :statistics="getStatistics()"
          @refetch-data="fetchStatistics()"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { isUserLoggedIn, getUserData, logout } from '@/auth/utils'
import store from '@/store'
import CreateUser from '@/views/dashboard/Components/CreateUser.vue'
import DownlineCounter from '@/views/dashboard/Components/DownlineCounter.vue'
import useStatisticsList from '@/views/dashboard/useStatisticsList'
import InformationTable from '@/views/dashboard/Components/InformationTable.vue'
import VpcsCard from '@/views/dashboard/Components/VpcsCards.vue'
import UserNearQualification from '@/views/dashboard/Components/UserNearQualification.vue'
import UpgradeFromCliente from '@/views/dashboard/Components/UpgradeFromCliente.vue'
import UserCommissions from '@/views/dashboard/Components/UserCommissions.vue'
import router from '@/router'

export default {
  components: {
    UserCommissions,
    UpgradeFromCliente,
    UserNearQualification,
    VpcsCard,
    InformationTable,
    DownlineCounter,
    CreateUser,
    BRow,
    BCol,
  },
  data() {
    return {
      data: {},
      text: null,
      paymentMethod: null,
    }
  },
  mounted() {
    if (!isUserLoggedIn()) {
      this.$router.push({ name: 'auth-login' })
    }

    if ((this.$route.params && this.$route.params.order !== undefined) || (this.$route.query && this.$route.query.order !== undefined)) {
      this.paymentMethod = this.$route.params && this.$route.params.order !== undefined ? this.$route.params.order : this.$route.query.order
      if (this.paymentMethod === 'carta') {
        this.text = 'Il tuo ordine è stato inoltrato'
      } else if (this.paymentMethod === 'bonifico') {
        this.text = 'Per confermare il tuo ordine effettua il bonifico alle seguenti coordinate'
      } else {
        this.text = 'Per confermare il tuo ordine effettua il pagamento in contanti presso la nostra sede, in Via calamandrei 120/i 52100 Arezzo'
      }
      this.$swal({
        title: 'Ordine Inviato',
        text: this.text,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    }

    if (this.$can('store', 'Orders')) {
      this.fetchCartProducts()
    }
  },
  beforeCreate() {
    if (!isUserLoggedIn()) {
      this.$router.push({ name: 'auth-login' })
    }
  },
  create() {
    if (!isUserLoggedIn()) {
      this.$router.push({ name: 'auth-login' })
    }
  },
  setup() {
    if (getUserData().role !== 'admin' && getUserData().privacy_accepted === undefined) {
      logout()
    }
    if (getUserData().privacy_accepted === null) {
      router.push({ name: 'privacy' })
    }

    const fetchCartProducts = () => {
      store.dispatch('ecommerce/fetchCartProducts')
        .then(response => {
          store.commit('ecommerce/UPDATE_CART_ITEMS_COUNT', response.data.data.meta.total)
        })
    }

    const {
      fetchStatistics,
      getStatistics,
    } = useStatisticsList()
    fetchStatistics()

    return {
      fetchCartProducts,

      fetchStatistics,
      getStatistics,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
