<template>
  <b-row
    v-if="statistics"
    class="match-height"
  >
    <b-col
      class="d-block"
      lg="2"
    >
      <b-card
        no-body
      >
        <statistic-card-horizontal
          icon="ArrowLeftIcon"
          :statistic="statistics.utente.vpcs.vpc_left"
          statistic-title="Gamba sinistra"
        />
      </b-card>
    </b-col>
    <b-col
      class="d-block"
      lg="2"
    >
      <b-card
        no-body
      >
        <statistic-card-horizontal
          icon="ArrowRightIcon"
          :statistic="statistics.utente.vpcs.vpc_right"
          statistic-title="Gamba destra"
        />
      </b-card>
    </b-col>
    <b-col
      class="d-block"
      lg="2"
    >
      <b-card
        no-body
      >
        <statistic-card-horizontal
          icon="CornerRightUpIcon"
          :statistic="statistics.utente.vpcs.carry_over"
          statistic-title="Riporto mese scorso"
        />
      </b-card>
    </b-col>
    <b-col
      class="d-block"
      lg="3"
    >
      <b-card
        no-body
      >
        <statistic-card-horizontal
          icon="ArrowDownIcon"
          :statistic="statistics.utente.vpcs.weak_leg"
          statistic-title="Gamba debole"
          color="success"
        />
      </b-card>
    </b-col>
    <b-col
      class="d-block"
      lg="3"
    >
      <b-card
        no-body
      >
        <statistic-card-horizontal
          icon="PlusIcon"
          :statistic="statistics.utente.vpcs.total"
          statistic-title="Totale gamba forte"
          color="success"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard,
} from 'bootstrap-vue'
import useUserList from '@/views/iscritti/iscritti/useUserList'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  name: 'VpcsCard',
  components: {
    StatisticCardHorizontal,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    statistics: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        grid: {
          show: false,
          padding: {
            left: -15,
            right: -15,
            top: -12,
            bottom: -15,
          },
        },
        colors: [$themeColors.primary],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '22%',
            },
            track: {
              background: '#e9ecef',
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
      },
    }
  },
  setup() {
    const {
      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
    } = useUserList()

    return {
      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/include';
  @import '~@core/scss/base/components/variables-dark';

  .card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
      background: $theme-dark-body-bg !important;
    }
  }
</style>
