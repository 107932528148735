<template>
  <!-- modal -->
  <b-modal
    :id="'modal-richiesta-provvigione'"
    :ref="'modal-richiesta-provvigione'"
    :title="'Richiedi provvigione'"
    ok-title="Richiedi"
    cancel-title="Chiudi"
    cancel-variant="outline-secondary"
    size="md"
    centered
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <validation-observer ref="richiestaCommissioneForm">
      <form
        ref="form"
        class="repeater-form"
        @submit.stop.prevent="validationForm"
      >

        <b-row class="match-height">

          <b-col
            lg="12"
            md="12"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="type"
              :rules="'required|between:1,'+user.tot_commissions"
            >
              <b-form-group
                label="Importo"
                label-for="type-input"
                invalid-feedback="Status is required"
              >
                <b-input-group
                  prepend="€"
                  append=".00"
                >
                  <b-form-input
                    v-model="sendData.commission"
                    placeholder="100"
                  />
                </b-input-group>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  l'importo deve essere compreso tra 1 e {{ user.tot_commissions }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

      </form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BFormInput, BModal, VBModal, BRow, BCol, BFormInvalidFeedback, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, integer, digits,
} from '@validations'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BInputGroup,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      integer,
      digits,
    }
  },
  methods: {
    validationForm() {
      this.$refs.richiestaCommissioneForm.validate().then(success => {
        if (success) {
          this.handleSubmit()
        }
      })
    },
    resetModal() {
      this.resetitemData()
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.validationForm()
    },
    handleSubmit() {
      this.onSubmit()

      // Hide the modal manually
      this.$nextTick(() => {
      })
    },
  },
  setup(props, { emit, refs }) {
    // Use toast
    const toast = useToast()

    const blankData = {
      commission: null,
    }

    const sendData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetitemData = () => {
      sendData.value = JSON.parse(JSON.stringify(blankData))
    }

    const onSubmit = () => {
      store.dispatch('commissions/addRichiestaCommissione', sendData.value)
        .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          emit('refetch-data')
          refs['modal-richiesta-provvigione'].toggle('#toggle-btn')
        })
        .catch(error => {
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetitemData)

    return {
      sendData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      resetitemData,
    }
  },
}
</script>

<style lang="scss" scoped>
  .repeater-form {
    overflow: hidden;
    transition: .35s height;
  }
</style>
