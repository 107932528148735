<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>Incaricati vicini alla qualifica {{ Object.values(statistics.utenti_vicini_alla_qualifica).length }}</b-card-title>
        <b-card-text class="font-small-2" />
      </div>
    </b-card-header>
    <b-card-body class="mb-2">
      <b-tabs
        v-if="Object.values(statistics.utenti_vicini_alla_qualifica).length > 0"
        content-class="mt-1"
      >
        <b-tab
          v-for="(qualification, index) in statistics.utenti_vicini_alla_qualifica"
          :key="qualification"
          :title="index"
          lazy
        >
          <b-alert
            variant="info"
            show
            class="mb-0"
          >
            <div class="alert-body">
              <b-list-group>
                <b-list-group-item
                  v-for="user in qualification"
                  :key="user.id"
                  class="flex-column align-items-start"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-0">
                      {{ user.fullName }}
                    </h5>
                    <small class="text-secondary">{{ user.code }}</small>
                    <small class="text-secondary">Gamba debole: {{ user.vpcs.weak_leg }}</small>
                    <small class="text-secondary">Gamba forte: {{ user.vpcs.strong_leg }}</small>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-alert>
        </b-tab>
      </b-tabs>
      <small
        v-else
        class="mb-0 text-secondary"
      >
        Non ci sono incaricati vicini alla qualifica
      </small>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle, BCardHeader, BCardText, BTabs, BTab, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import useUserList from '@/views/iscritti/iscritti/useUserList'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'UserNearQualification',
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BCardText,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  props: {
    statistics: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
    } = useUserList()

    return {
      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
    }
  },
}
</script>

<style scoped>

</style>
