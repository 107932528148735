<template>
  <!-- modal -->
  <b-modal
    :id="'modal-create-user'"
    :ref="'modal-create-user'"
    :title="'Crea un nuovo iscritto'"
    ok-title="Salva"
    cancel-title="Chiudi"
    cancel-variant="outline-secondary"
    size="xl"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="transparent"
      :opacity="0.85"
      blur="2px"
      rounded="sm"
    >
      <validation-observer ref="createUserForm">
        <form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
          @submit.stop.prevent="validationForm"
        >
          <b-row class="match-height">

            <b-col
              lg="2"
              md="12"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="upline"
                rules="required"
              >
                <b-form-group
                  label="Upline"
                  label-for="upline_dir-input"
                  invalid-feedback="Obbligatorio"
                >
                  <v-select
                    v-model="userData.upline_dir"
                    :options="['sinistra', 'destra']"
                    :reduce="val => val"
                    :clearable="false"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-checkbox
                    v-if="loggedUser && loggedUser.qualification.can_select_upline || $can('users', 'Users')"
                    v-model="userData.manual_code"
                    :value="true"
                    :disabled="$can('users', 'Users')"
                  >
                    Inserimento manuale
                  </b-form-checkbox>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="(loggedUser && loggedUser.qualification.can_select_upline || $can('users', 'Users')) && userData.manual_code === true"
              lg="2"
              md="12"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="upline"
                rules="required|digits:6"
              >
                <b-form-group
                  label="Codice Upline"
                  label-for="upline_code-input"
                  invalid-feedback="Obbligatorio e max 6 numeri"
                >
                  <b-form-input
                    id="upline_code-input"
                    v-model="userData.upline_code"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="$can('users', 'Users')"
              lg="2"
              md="12"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="sponsor"
                rules="required|digits:6"
              >
                <b-form-group
                  label="Codice Sponsor"
                  label-for="sponsor_code-input"
                  invalid-feedback="Obbligatorio e max 6 numeri"
                >
                  <b-form-input
                    id="sponsor_code-input"
                    v-model="userData.sponsor_code"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="12"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="type"
                rules="required"
              >
                <b-form-group
                  label="Tipo"
                  label-for="type-input"
                  invalid-feedback="Status is required"
                >
                  <v-select
                    v-model="userData.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    Obbligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="$can('users', 'Users')"
              lg="2"
              md="12"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="status"
                rules="required"
              >
                <b-form-group
                  label="Status"
                  label-for="status-input"
                  invalid-feedback="Status is required"
                >
                  <v-select
                    v-model="userData.active"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    Obbligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="12"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  label="Nome"
                  label-for="name-input"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="name-input"
                    v-model="userData.name"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="12"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="last_name"
                rules="required"
              >
                <b-form-group
                  label="Cognome"
                  label-for="last_name-input"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="last_name-input"
                    v-model="userData.last_name"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="12"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="email"
                rules="required|email"
              >
                <b-form-group
                  label="Email"
                  label-for="email-input"
                  invalid-feedback="Obbligatorio e di tipo email"
                >
                  <b-form-input
                    id="email-input"
                    v-model="userData.email"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="12"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="Prefix"
                rules="required"
              >
                <b-form-group
                  label="Prefisso"
                  label-for="prefix-input"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="prefix-input"
                    v-model="userData.prefix"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="12"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="phone_number"
                rules="required"
              >
                <b-form-group
                  label="Telefono"
                  label-for="phone-input"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="phone-input"
                    v-model="userData.phone_number"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="12"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="fiscal_code"
                rules="required"
              >
                <b-form-group
                  label="Codice fiscale"
                  label-for="fiscal_code-input"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="fiscal_code-input"
                    v-model="userData.fiscal_code"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <template v-if="userData.type === 'incaricato'">

            <div class="divider my-1">
              <div class="divider-text">
                Documenti & info personali
              </div>
            </div>

            <b-row>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="birthplace"
                  rules="required"
                >
                  <b-form-group
                    label="Nato a"
                    label-for="birthplace-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="birthplace-input"
                      v-model="userData.incaricato.birthplace"
                      :state="getValidationState(validationContext)"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="province"
                  rules="required|alpha"
                >
                  <b-form-group
                    label="Provincia di"
                    label-for="province-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="province-input"
                      v-model="userData.incaricato.province"
                      :state="getValidationState(validationContext)"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="nation_of_birth"
                  rules="required"
                >
                  <b-form-group
                    label="Stato"
                    label-for="nation_of_birth-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="nation_of_birth-input"
                      v-model="userData.incaricato.nation_of_birth"
                      :state="getValidationState(validationContext)"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="gender"
                  rules="required"
                >
                  <b-form-group
                    label="Genere"
                    label-for="gender-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <v-select
                      v-model="userData.incaricato.gender"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="genderOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      :required="!userData.incaricato.gender"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Obbligatorio
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="identity_card"
                  rules="required"
                >
                  <b-form-group
                    label="Documento"
                    label-for="identity_card-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="identity_card-input"
                      v-model="userData.incaricato.identity_card"
                      :state="getValidationState(validationContext)"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="identity_card_number"
                  rules="required"
                >
                  <b-form-group
                    label="Numero documento"
                    label-for="identity_card_number-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="identity_card_number-input"
                      v-model="userData.incaricato.identity_card_number"
                      :state="getValidationState(validationContext)"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="public_authority"
                  rules="required"
                >
                  <b-form-group
                    label="Rilasciato da"
                    label-for="public_authority-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="public_authority-input"
                      v-model="userData.incaricato.public_authority"
                      :state="getValidationState(validationContext)"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >

                <b-form-group
                  label="Rilasciato il"
                  label-for="release_date"
                  invalid-feedback="Obbligatorio"
                >
                  <cleave
                    id="release_date-input"
                    v-model="userData.incaricato.release_date"
                    class="form-control"
                    :raw="false"
                    :options="{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }"
                    placeholder="GG/MM/AAAA"
                  />
                  <b-form-invalid-feedback :state="errors.release_date">
                    Obbligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <b-form-group
                  label="Scadenza il"
                  label-for="expiration_date"
                  invalid-feedback="Obbligatorio"
                >
                  <cleave
                    id="expiration_date-input"
                    v-model="userData.incaricato.expiration_date"
                    class="form-control"
                    :raw="false"
                    :options="{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }"
                    placeholder="GG/MM/AAAA"
                  />
                  <b-form-invalid-feedback :state="errors.expiration_date">
                    Obbligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <b-form-group
                  label="Nato il"
                  label-for="birthday"
                  invalid-feedback="Obbligatorio"
                >
                  <cleave
                    id="birthday-input"
                    v-model="userData.incaricato.birthday"
                    class="form-control"
                    :raw="false"
                    :options="{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }"
                    placeholder="GG/MM/AAAA"
                  />
                  <b-form-invalid-feedback :state="errors.birthday">
                    Obbligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="spouse"
                  rules="required"
                >
                  <b-form-group
                    label="Sposata/o"
                    label-for="spouse-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <v-select
                      v-model="userData.incaricato.spouse"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="siNoOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Obbligatorio
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="first_name_spouse"
                  rules=""
                >
                  <b-form-group
                    label="Nome coniuge"
                    label-for="first_name_spouse-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="first_name_spouse-input"
                      v-model="userData.incaricato.first_name_spouse"
                      :state="getValidationState(validationContext)"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="last_name_spouse"
                  rules=""
                >
                  <b-form-group
                    label="Cognome coniuge"
                    label-for="last_name_spouse-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="last_name_spouse-input"
                      v-model="userData.incaricato.last_name_spouse"
                      :state="getValidationState(validationContext)"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="inps"
                  rules="required"
                >
                  <b-form-group
                    label="Inps"
                    label-for="inps-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <v-select
                      v-model="userData.incaricato.inps"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="siNoOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Obbligatorio
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="retired"
                  rules="required"
                >
                  <b-form-group
                    label="Pensionata/o"
                    label-for="retired-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <v-select
                      v-model="userData.incaricato.retired"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="siNoOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Obbligatorio
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="iban"
                  rules=""
                >
                  <b-form-group
                    label="Iban"
                    label-for="iban-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="iban-input"
                      v-model="userData.incaricato.iban"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-row>

          </template>
          <template v-else-if="userData.type === 'cliente'">

            <div class="divider my-1">
              <div class="divider-text">
                Informazioni
              </div>
            </div>

            <b-row>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >

                <validation-provider
                  #default="validationContext"
                  name="iban"
                  rules=""
                >
                  <b-form-group
                    label="Azienda"
                    label-for="company-input"
                  >
                    <v-select
                      v-model="userData.company"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="siNoOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      @input="userData.cliente.business_name = null;
                              userData.cliente.vat = null;"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Obbligatorio
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <template v-if="userData.company">
                <b-col
                  lg="2"
                  md="12"
                  sm="12"
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="business_name"
                    rules="required"
                  >
                    <b-form-group
                      label="Nome Azienda"
                      label-for="business_name-input"
                      invalid-feedback="Obbligatorio"
                    >
                      <b-form-input
                        id="business_name-input"
                        v-model="userData.cliente.business_name"
                        :state="getValidationState(validationContext)"
                        required
                        :readonly="!userData.company"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  lg="2"
                  md="12"
                  sm="12"
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="vat"
                    rules="required|integer"
                  >
                    <b-form-group
                      label="P.IVA"
                      label-for="vat-input"
                      invalid-feedback="Obbligatorio e numerico"
                    >
                      <b-form-input
                        id="vat-input"
                        v-model="userData.cliente.vat"
                        :state="getValidationState(validationContext)"
                        required
                        :readonly="!userData.company"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  lg="2"
                  md="12"
                  sm="12"
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="uniquecode"
                    rules="min:6"
                  >
                    <b-form-group
                      label="Codice univoco"
                      label-for="uniquecode-input"
                      invalid-feedback="Minimo 6 caratteri"
                    >
                      <b-form-input
                        id="uniquecode-input"
                        v-model="userData.cliente.uniquecode"
                        :state="getValidationState(validationContext)"
                        required
                        :readonly="!userData.company"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </template>

            </b-row>

            <div class="divider my-1">
              <div class="divider-text">
                Ordine
              </div>
            </div>

            <b-row>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="scatole"
                  rules=""
                >
                  <b-form-group
                    label="Numero Scatole"
                    label-for="scatole-input"
                  >
                    <v-select
                      v-model="userData.ordine.scatole"
                      :options="quantityOptions"
                      :reduce="val => val"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Obbligatorio
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="caffe"
                  rules=""
                >
                  <b-form-group
                    label="Caffe"
                    label-for="caffe-input"
                  >
                    <v-select
                      v-model="userData.ordine.caffe"
                      :options="caffeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Obbligatorio
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="2"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="macchina"
                  rules=""
                >
                  <b-form-group
                    label="Macchina"
                    label-for="macchina-input"
                  >
                    <v-select
                      v-model="userData.ordine.macchina"
                      :options="resolveMacchine()"
                      :reduce="val => val.value"
                      :clearable="false"
                      :disabled="userData.ordine.scatole <= 1"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Obbligatorio
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-row>

          </template>

          <div class="divider my-3">
            <div class="divider-text">
              Indirizzo
            </div>
          </div>

          <b-col
            lg="12"
            md="12"
          >

            <!--          <b-button-->
            <!--            v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
            <!--            variant="primary"-->
            <!--            size="sm"-->
            <!--            class="mt-2 mb-2"-->
            <!--            @click="repeateAgain"-->
            <!--          >-->
            <!--            <feather-icon-->
            <!--              icon="PlusIcon"-->
            <!--              class="mr-25"-->
            <!--            />-->
            <!--            <span>Add New</span>-->
            <!--          </b-button>-->

            <!-- Row Loop -->
            <b-row
              v-for="(address, index) in userData.addresses"
              :id="address.id"
              :key="address.id"
              ref="row"
            >
              <!-- Item Name -->
              <b-col>
                <b-row>

                  <b-col
                    lg="2"
                    md="12"
                    sm="12"
                    cols="12"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="street"
                      rules="required"
                    >
                      <b-form-group
                        label="Via"
                        label-for="street-input"
                        invalid-feedback="Obbligatorio"
                      >
                        <b-form-input
                          :id="'street-input-'+index"
                          v-model="userData.addresses[index].street"
                          :state="getValidationState(validationContext)"
                          required
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    lg="2"
                    md="12"
                    sm="12"
                    cols="12"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="number"
                      rules="required"
                    >

                      <b-form-group
                        label="Numero"
                        label-for="number-input"
                        invalid-feedback="Obbligatorio"
                      >

                        <b-form-input
                          :id="'number-input-'+index"
                          v-model="userData.addresses[index].number"
                          :state="getValidationState(validationContext)"
                          required
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    lg="2"
                    md="12"
                    sm="12"
                    cols="12"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="city"
                      rules="required"
                    >

                      <b-form-group
                        label="Città"
                        label-for="city-input"
                        invalid-feedback="Obbligatorio"
                      >

                        <b-form-input
                          :id="'city-input-'+index"
                          v-model="userData.addresses[index].city"
                          :state="getValidationState(validationContext)"
                          required
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    lg="2"
                    md="12"
                    sm="12"
                    cols="12"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="province"
                      rules="required|alpha"
                    >

                      <b-form-group
                        label="Provincia"
                        label-for="province-input"
                        invalid-feedback="Obbligatorio"
                      >

                        <b-form-input
                          :id="'province-input-'+index"
                          v-model="userData.addresses[index].province"
                          :state="getValidationState(validationContext)"
                          required
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    lg="2"
                    md="12"
                    sm="12"
                    cols="12"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="zip"
                      rules="required"
                    >

                      <b-form-group
                        label="CAP"
                        label-for="zip-input"
                        invalid-feedback="Obbligatorio"
                      >

                        <b-form-input
                          :id="'zip-input-'+index"
                          v-model="userData.addresses[index].zip"
                          :state="getValidationState(validationContext)"
                          required
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    v-if="userData.addresses.length > 1"
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      size="sm"
                      @click="removeItem(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>Delete</span>
                    </b-button>
                  </b-col>

                </b-row>
                <b-row>
                  <b-col v-if="userData.addresses.length > 1">
                    <hr>
                  </b-col>
                </b-row>
              </b-col>

            </b-row>

          </b-col>

        </form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BRow,
  BCol,
  BButton,
  BFormInvalidFeedback,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, integer, digits,
} from '@validations'
import Cleave from 'vue-cleave-component'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useOrderList from '@/views/ordini/useOrderList'
import useUserList from '@/views/iscritti/iscritti/useUserList'
import { can } from '@core/libs/acl/utils'
import ability from '@/libs/acl/ability'
import useStatisticsList from '@/views/dashboard/useStatisticsList'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BModal,
    vSelect,
    BRow,
    BCol,
    BButton,
    BFormInvalidFeedback,
    BFormCheckbox,
    BOverlay,
    Cleave,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    typeOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    genderOptions: {
      type: Array,
      required: true,
    },
    siNoOptions: {
      type: Array,
      required: true,
    },
    uplineCode: {
      type: String,
      required: false,
      default: null,
    },
    manualCode: {
      type: Boolean,
      required: false,
      default: ability.can('users', 'Users'),
    },
    uplineDir: {
      type: String,
      required: false,
      default: 'sinistra',
    },
  },
  data() {
    return {
      required,
      alphaNum,
      integer,
      digits,
      nextTodoId: 0,
    }
  },
  watch: {
    uplineCode(newVal) {
      this.userData.upline_code = newVal
    },
    manualCode(newVal) {
      this.userData.manual_code = newVal
    },
    uplineDir(newVal) {
      this.userData.upline_dir = newVal
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {

    resolveMacchine() {
      if (this.userData.ordine.scatole <= 1) {
        return [this.macchinaOptions[0]]
      }
      if (this.userData.ordine.scatole > 1 && this.userData.ordine.scatole < 10) {
        return [this.macchinaOptions[0], this.macchinaOptions[1]]
      }
      return this.macchinaOptions
    },

    validationForm() {
      this.$refs.createUserForm.validate()
        .then(success => {
          if (success) {
            this.handleSubmit()
          }
        })
    },
    resetModal() {
      // this.resetuserData()
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.validationForm()
    },
    handleSubmit() {
      this.onSubmit()

      // Hide the modal manually
      // this.$nextTick(() => {
      //   this.$refs['modal-create-user'].toggle('#toggle-btn')
      // })
    },
    repeateAgain() {
      this.userData.addresses.push({
        id: this.nextTodoId += 1,
        street: null,
        number: null,
        city: null,
        province: null,
        zip: null,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.userData.addresses.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup(props, {
    emit,
    refs,
    root,
  }) {
    // Use toast
    const toast = useToast()

    const errors = ref({
      release_date: null,
      expiration_date: null,
      birthday: null,
    })

    const show = ref(false)

    const blankUserData = {
      sponsor_code: null,
      upline_code: props.uplineCode,
      manual_code: props.manualCode,
      upline_dir: props.uplineDir,
      id: null,
      code: null,
      name: null,
      last_name: null,
      email: null,
      type: 'cliente',
      company: 0,
      active: 1,
      prefix: '39',
      phone_number: null,
      fiscal_code: null,
      incaricato: [],
      cliente: [],
      addresses: [{
        id: 0,
        street: null,
        number: null,
        city: null,
        province: null,
        cap: null,
        zip: null,
      }],
      ordine: {
        scatole: 1,
        caffe: 'forte',
        macchina: 'base',
      },
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const {
      fetchStatistics,
    } = useStatisticsList()

    function checkRequired() {
      if (userData.value.type === 'incaricato') {
        errors.value.release_date = !!userData.value.incaricato.release_date
        errors.value.expiration_date = !!userData.value.incaricato.expiration_date
        errors.value.birthday = !!userData.value.incaricato.birthday
        return !!userData.value.incaricato.release_date && !!userData.value.incaricato.expiration_date && !!userData.value.incaricato.birthday
      }
      return true
    }

    const onSubmit = () => {
      if (checkRequired()) {
        show.value = !show.value
        let sendData = { ...userData.value }
        if (sendData.type === 'incaricato') {
          const { incaricato } = sendData
          delete sendData.incaricato
          sendData = { ...sendData, ...incaricato }
        } else {
          const { cliente } = sendData
          delete sendData.cliente
          sendData = { ...sendData, ...cliente }
        }
        // console.log(sendData.upline_code, sendData.upline_dir)
        // if (sendData.upline_code != null) {
        //   sendData.upline_dir = null
        // }
        store.dispatch('users/addIscritto', sendData)
          .then(() => {
            emit('refetch-data')
            refs['modal-create-user'].toggle('#toggle-btn')
            fetchStatistics()
            root.$swal({
              title: 'Successo',
              text: 'Utente creato con successo',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            show.value = false
          })
          .catch(error => {
            console.log(error.response)
            Object.values(error.response.data)
              .forEach(message => {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: message[0],
                    icon: 'AlertTriangleIcon',
                    variant: 'warning',
                  },
                })
              })
            if (error.response.status === 500) {
              root.$swal({
                title: 'Errore',
                text: 'Problemi nella creazione utente',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
            show.value = false
          })
      }
    }

    const {
      macchinaOptions,
      quantityOptions,
      caffeOptions,
    } = useOrderList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    const {
      refetchLoggedUser,
      loggedUser,
    } = useUserList()
    if (!can('users', 'Users')) {
      refetchLoggedUser()
    }

    return {
      show,
      errors,

      refetchLoggedUser,
      loggedUser,

      macchinaOptions,
      quantityOptions,
      caffeOptions,

      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      resetuserData,
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
