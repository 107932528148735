<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>Commissioni</b-card-title>
        <b-card-text class="font-small-2">
          Totale commissionabile
        </b-card-text>
      </div>
    </b-card-header>
    <b-card-body class="mb-2">
      <h3 class="mb-75 mt-2 pt-50">
        €{{ statistics.utente.tot_commissions }}
      </h3>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        :disabled="statistics.utente.tot_commissions < 1"
        @click="$bvModal.show('modal-richiesta-provvigione')"
      >
        Richiedi provvigione
      </b-button>
    </b-card-body>

    <commissioni-demand
      v-if="statistics.utente.tot_commissions > 0"
      :user="statistics.utente"
      @refetch-data="$emit('refetch-data')"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle, BCardHeader, BCardText, BButton,
} from 'bootstrap-vue'
import useUserList from '@/views/iscritti/iscritti/useUserList'
import Ripple from 'vue-ripple-directive'
import CommissioniDemand from '@/views/commissioni-richieste/commissioni-demand/CommissioniDemand.vue'

export default {
  name: 'UserCommissions',
  components: {
    CommissioniDemand,
    BCard,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BCardText,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    statistics: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
    } = useUserList()

    return {
      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
    }
  },
}
</script>

<style scoped>

</style>
