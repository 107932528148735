<template>
  <b-card>
    <b-card-header>
      <b-card-title />
    </b-card-header>
    <b-card-body class="mb-2">
      <b-button
        v-if="$can('store', 'Users')"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="btn-block"
        @click="$bvModal.show('modal-create-user')"
      >
        Aggiungi incaricato o cliente
      </b-button>
      <user-list-create
        v-if="$can('store', 'Users')"
        :type-options="typeOptions"
        :status-options="statusOptions"
        :gender-options="genderOptions"
        :si-no-options="siNoOptions"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardBody, BCardTitle, BCardHeader,
} from 'bootstrap-vue'
import UserListCreate from '@/views/iscritti/iscritti/iscritti-create/UserListCreate.vue'
import useUserList from '@/views/iscritti/iscritti/useUserList'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'CreateUser',
  components: {
    UserListCreate,
    BButton,
    BCard,
    BCardBody,
    BCardTitle,
    BCardHeader,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
    } = useUserList()

    return {
      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
    }
  },
}
</script>

<style scoped>

</style>
