<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>Info generali</b-card-title>
        <b-card-text class="font-small-2" />
      </div>

    </b-card-header>

    <!-- body -->
    <b-card-body>

      <div
        class="browser-states"
      >
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar
              rounded
              size="30"
              variant="success"
            >
              <feather-icon
                size="18"
                icon="CheckIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="align-self-center my-auto">
              Qualifica attuale
            </h6>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">{{ statistics.utente.personal_data.incaricato.qualifica.qualifica_name }}</span>
          {{ statistics.utente.personal_data.incaricato.qualifica.vp_min }} - {{ statistics.utente.personal_data.incaricato.qualifica.vp_max }} Vpcs
        </div>
      </div>

      <div
        class="browser-states"
      >
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar
              rounded
              size="30"
              variant="info"
            >
              <feather-icon
                size="18"
                icon="EyeIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="align-self-center my-auto">
              Qualifica nominale
            </h6>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">{{ statistics.utente.qualification_nominal.name }}</span>
          {{ statistics.utente.qualification_nominal.vp_min }} - {{ statistics.utente.qualification_nominal.vp_max }} Vpcs
        </div>
      </div>

      <div
        class="browser-states"
      >
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar
              rounded
              size="30"
              variant="danger"
            >
              <feather-icon
                size="18"
                icon="TrendingUpIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="align-self-center my-auto">
              Prossima qualifica
            </h6>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">{{ statistics.utente.next_qualification.name }}</span>
          {{ statistics.utente.vpcs.weak_leg }} su {{ statistics.utente.next_qualification.vp_min }}
          <vue-apex-charts
            class="ml-1"
            type="radialBar"
            height="30"
            width="30"
            :options="options"
            :series="[(100 * statistics.utente.vpcs.weak_leg) / statistics.utente.next_qualification.vp_min]"
          />
          <span class="font-weight-bold text-body-heading">
            {{ (100 * statistics.utente.vpcs.weak_leg) / statistics.utente.next_qualification.vp_min }} %
            Vpcs
          </span>
        </div>
      </div>
      <div
        class="browser-states"
      >
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar
              rounded
              size="30"
              variant="primary"
            >
              <feather-icon
                size="18"
                icon="RefreshCwIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="align-self-center my-auto">
              Stato autoconsumo
            </h6>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <b-media-aside class="mr-1">
            <b-avatar
              size="25"
              :variant="statistics.utente.subscription.includes('Attivo') ? 'success' : statistics.utente.subscription === 'Inattivo' ? 'danger' : 'secondary'"
            >
              <feather-icon
                size="13"
                :icon="statistics.utente.subscription.includes('Attivo') ? 'CheckIcon' : statistics.utente.subscription === 'Inattivo' ? 'XIcon' : 'MinusIcon'"
              />
            </b-avatar>
          </b-media-aside>
          <span class="font-weight-bold text-body-heading">
            {{ statistics.utente.subscription }}
          </span>
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BAvatar, BMediaAside, BMediaBody,
} from 'bootstrap-vue'
import useUserList from '@/views/iscritti/iscritti/useUserList'
import Ripple from 'vue-ripple-directive'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  name: 'InformationTable',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    VueApexCharts,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    Ripple,
  },
  props: {
    statistics: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        grid: {
          show: false,
          padding: {
            left: -15,
            right: -15,
            top: -12,
            bottom: -15,
          },
        },
        colors: [$themeColors.primary],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '22%',
            },
            track: {
              background: '#e9ecef',
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
      },
    }
  },
  setup() {
    const {
      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
    } = useUserList()

    return {
      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/include';
  @import '~@core/scss/base/components/variables-dark';

  .card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
      background: $theme-dark-body-bg !important;
    }
  }
</style>
