<template>
  <b-card>
    <b-card-header>
      <b-card-title />
    </b-card-header>
    <b-card-body class="mb-2">
      <b-button
        v-if="!$can('store', 'Users')"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="flat-primary"
        class="btn-block"
        :to="{ name: 'area-personale' }"
      >
        Effettua l'upgrade a incaricato
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardBody, BCardTitle, BCardHeader,
} from 'bootstrap-vue'
import useUserList from '@/views/iscritti/iscritti/useUserList'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'UpgradeFromCliente',
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardTitle,
    BCardHeader,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
    } = useUserList()

    return {
      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
    }
  },
}
</script>

<style scoped>

</style>
